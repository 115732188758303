<template>
    <div>
    <div class="card-body p-4 w-100 bg-current border-0 d-flex rounded-3">
        <router-link to="/settings" class="d-inline-block mt-2"><i class="mico-feather-12 font-sm text-white"></i></router-link>
        <h4 class="font-xs text-white fw-600 ms-4 mb-0 mt-2">Manage Wallets</h4>
    </div>
    <div class="card-body p-lg-5 p-4 w-100 border-0 dfinity">
        <h4 class="font-xs fw-600">Dfinity Wallet </h4>
        <p  v-if="this.userWallets.dfinity" class="card-info" >Principal Id : 
            <span v-if="this.userWallets.dfinity.principalId" v-html="this.userWallets.dfinity.principalId"></span>
        </p>
        <p  v-if="this.userWallets.dfinity" class="card-info">Wallet Conneted : <span v-html="this.userWallets.dfinity.walletType?this.userWallets.dfinity.walletType:'none'"></span></p>
        <div class="row  mt-4 justify-content-start">
                <div class="col-lg-4">
                    <a href="#" id="plugwallet" class="wallet-container plugwallet" @click.prevent="connectPlug" :class="walletStat.plug?'connected':''">
                        <img src="/theme/img/wallet/plug.svg" alt="" class="mb20">
                        <h4>Plug</h4>
                    </a>
                </div>
                <div class="col-lg-4">
                    <a href="#" id="stoicwallet" class="wallet-container stoicwallet" @click.prevent="connectStoic" :class="walletStat.stoic?'connected':''">
                        <img src="/theme/img/wallet/stoic.png" alt="" class="mb20">
                        <h4>Stoic</h4>
                    </a>
                </div>
        </div>
    </div>
     <div class="card-body p-lg-5 p-4 w-100 border-0 solana">
        <h4 class="font-xs fw-600">Solana Wallet </h4>
         <p  v-if="this.userWallets.solana" class="card-info" >Wallet Address: 
            <span v-if="this.userWallets.solana.address" v-html="this.userWallets.solana.address"></span>
        </p>
         <p  v-if="this.userWallets.solana" class="card-info">Wallet Conneted : <span v-html="this.userWallets.solana.walletType?this.userWallets.solana.walletType:'none'"></span></p>
        <div class="row  mt-4 justify-content-start">
            <div class="col-lg-4">
                <a href="#" id="phantomwallet" class="wallet-container phantomwallet" @click.prevent="connectPhanthom" :class="walletStat.phantom?'connected':''">
                    <img src="/theme/img/wallet/phantom.svg" alt="" class="mb20">
                    <h4>Phantom</h4>
                </a>
            </div>
                <div class="col-lg-4">
                <a href="#" id="solflarewallet" class="wallet-container solflarewallet" @click.prevent="connectsolflare" :class="walletStat.solflare?'connected':''">
                    <img  src="/theme/img/wallet/solflare.svg" alt="" class="mb20">
                    <h4>Solflare</h4>
                </a>
            </div>
        </div>
     </div>
    </div>
</template>
<style scoped>
    .card-info{
        user-select: none;
        color: var(--text-grey);
    }
    .card-info span{
        padding: 4px 10px;
        background: var(--text-grey);
        border-radius: 4px;
        color: var(--white-clr);
        user-select: text;
        font-size: var(--text-small);
    
    word-break: break-all;
    }
    .card-info span:active{
        user-select: all;
    }
    .solflarewallet img{
        width: 74px;
    }
    @media (max-width: 768px) {
        .card-info span{
            width: 100%;
            display: inline-flex;
            margin-top: 5px;
        }
    }
</style>
<script>
import noty from 'noty'
import axios from '@/plugins/axios'
import whoamiIDL from '@/assets/js/whoami.did.js';
window.whoamiIDL = whoamiIDL;


export default {
    data () {
        return {
            userWallets:{},
            walletStat:{
                plug:false,
                phantom:false,
                solflare:false,
                stoic:false
            }
        }
    },
    created: async function () {
        var plugin = document.createElement("script");
        plugin.setAttribute( "src", "/theme/js/ext.browser.js");
        plugin.async = true;
        await document.head.appendChild(plugin);

        var plugin = document.createElement("script");
        plugin.setAttribute( "src", "/theme/js/whoami.did.js");
        plugin.async = true;
        await document.head.appendChild(plugin);

        
        var plugin = document.createElement("script");
        plugin.setAttribute( "src", "/theme/js/ic-stoic-identity.js");
        plugin.async = true;
        await document.head.appendChild(plugin);
  },
    methods: {
        connectPhanthom:async function(){
            var provider= false
             if("solana" in window){
                provider = window.solana;
                if (provider.isPhantom) {
                    provider = provider
                }
                var resp = await window.solana.connect();
                var pubkey = resp.publicKey.toString()
                if(pubkey){
                    this.walletInfoUpdate('solana', false , 'phantom' , pubkey);
                    this.walletStat.phantom=true;
                    this.walletStat.solflare=false;
                    new noty({text: 'Phantom wallet connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                }
                
             }else{
                 new noty({text: 'Phantom wallet not installed' , type:"error", layout:"bottomRight" , timeout:1000}).show();
             }
        },
        connectsolflare:async function(){
            var provider= false
             if("solflare" in window){
                provider = window.solflare;
                if (provider.isSolflare) {
                    provider = provider
                }
                var resp = await window.solflare.connect();
                if(resp){
                    var pubkey =await window.solflare.publicKey.toString()
                    this.walletInfoUpdate('solana', false , 'solflare' , pubkey);
                    this.walletStat.solflare=true;
                    this.walletStat.phantom=false;
                    new noty({text: 'Solflare wallet connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                }
                
             }else{
                 new noty({text: 'Solflare wallet not installed' , type:"error", layout:"bottomRight" , timeout:1000}).show();
             }
        },
        connectStoic:async function(){
            var identity = await StoicIdentity.load();
            var API = extjs.connect();
            API.setIdentity(identity);
            API.idl('qlfqk-fqaaa-aaaah-qakfq-cai', window.whoamiIDL);
            window.API = API;
            var whoami = await API.canister('qlfqk-fqaaa-aaaah-qakfq-cai').whoami();
            if(whoami){
                this.walletInfoUpdate('dfinity', whoami.toText() , 'stoic');
                this.walletStat.stoic =true
                this.walletStat.plug=false
                new noty({text: 'Stoic wallet connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
            }else{
                new noty({text: 'Uable to connect Stoic' , type:"error", layout:"bottomRight" , timeout:1000}).show();
            }
            StoicIdentity.disconnect();
        },
        connectPlug: async function(){
            if(window.ic && this.walletStat.plug == false){
                var nnsCanisterId = this.$store.state.canisterId
                var whitelist = [nnsCanisterId];
                var host =  this.$store.state.appUrl;
                var result = await window.ic.plug.requestConnect({whitelist});
                var principalObj = await window.ic.plug.agent.getPrincipal();
                
                if(principalObj){
                    this.checkPlugConnected()
                    this.walletStat.stoic=false;
                    this.walletInfoUpdate('dfinity', principalObj.toString() , 'plug');
                    new noty({text: 'Plug wallet connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                }
            }else{
                if( this.walletStat.plug){
                    new noty({text: 'Plug wallet already connected' , type:"success", layout:"bottomRight" , timeout:1000}).show();
                }else{
                    new noty({text: 'Plug wallet not installed' , type:"error", layout:"bottomRight" , timeout:1000}).show();
                }
                
            }
        },
        checkPlugConnected:async function(){
            var result = await window.ic.plug.isConnected();
            if(result){
                this.walletStat.plug=true;
            }    
        },
        getWalletInfo:async function(){
            var walletsInfo = await axios.get('/api/user/mywallets')
            this.userWallets = walletsInfo.data.data.userWallets;
            this.checkConnectStat()
        },
        walletInfoUpdate:async function(chain , principalId , wtype , address=false){
            var walletObj ={}
            if(chain =='dfinity'){
                this.userWallets.dfinity = {principalId: principalId , walletType:wtype}
                walletObj = { 'chain':chain, "prinId": principalId ,"walletType": wtype}
            }else if(chain =='solana'){
                this.userWallets.solana = {address: address , walletType:wtype}
                walletObj = { 'chain':chain, "address": address ,"walletType": wtype}
            }
            axios.post('/api/user/mywallets',walletObj ).then(res=>{ })
        },
        checkConnectStat:async function(){
            if(this.userWallets.dfinity){
                if(this.userWallets.dfinity.walletType=='plug'){
                    if(this.userWallets.dfinity.principalId!='' && this.userWallets.dfinity.principalId.length==63){
                        this.checkPlugConnected()
                         this.walletStat.plug =true
                    }
                }
                else if(this.userWallets.dfinity.walletType=='stoic'){ 
                    this.walletStat.stoic=true;
                    // var identity = await StoicIdentity.load();
                    // var API = extjs.connect();
                    // API.setIdentity(identity);
                    // API.idl('qlfqk-fqaaa-aaaah-qakfq-cai', window.whoamiIDL);
                    // window.API = API;
                    // var whoami = await API.canister('qlfqk-fqaaa-aaaah-qakfq-cai').whoami();
                    // if(whoami){
                    //     console.log(whoami.toText())
                    // }
                }
            }
            if(this.userWallets.solana){
                 if(this.userWallets.solana.walletType=='phantom'){
                     if(this.userWallets.solana.address!=''){
                        //await window.solana.connect({ onlyIfTrusted: true });
                        this.walletStat.phantom=true;
                     }
                 }
                else if(this.userWallets.solana.walletType=='solflare'){
                      if(this.userWallets.solana.address!=''){
                           var resp = await window.solflare.connect();
                            if(resp){
                                var pubkey =window.solflare.publicKey.toString()
                                 this.walletStat.solflare=true;
                            }
                        }
                  }
            }
        }
    },
    mounted:function(){
        this.getWalletInfo()
        if(!window.ic){
            document.querySelector('#plugwallet').setAttribute("href","https://plugwallet.ooo/")
            document.querySelector('#plugwallet').setAttribute("target","_blank")
        }
        if(!window.solana){
            document.querySelector('#phantomwallet').setAttribute("href","https://phantom.app/")
            document.querySelector('#phantomwallet').setAttribute("target","_blank")
        }
           // this.checkPlugConnected()
        
    }
}
</script>